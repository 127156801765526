import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { BounceLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Icons for success and error
import Pricing from "../components/Pricing";

Modal.setAppElement("#root"); // Ensure accessibility by setting app root for the modal

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "both",
    location: "",
    company: "",
    profileImage: null,
  });

  const [profilePreview, setProfilePreview] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMembershipMessageModal, setShowMembershipMessageModal] =
    useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success"); // 'success' or 'error'
  const [isFormValid, setIsFormValid] = useState(false); // State to track form validity
  const [isPhoneValid, setIsPhoneValid] = useState(true); // State to track phone validation
  const [suburbs, setSuburbs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuburbs = async () => {
      if (formData.location.length > 0) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`/api/suburbs`, {
            params: { query: formData.location },
          });
          setSuburbs(response.data);
          setShowDropdown(true);
        } catch (error) {
          console.error("Error fetching suburb data:", error);
          setSuburbs([]);
          setShowDropdown(false);
        } finally {
          setLoading(false);
        }
      } else {
        setShowDropdown(false);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchSuburbs();
    }, 300); // Debounce time in milliseconds

    return () => clearTimeout(debounceFetch);
  }, [formData.location]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profileImage: e.target.files[0], // Access the first file in the files array
    });

    setProfilePreview(URL.createObjectURL(e.target.files[0])); // Set preview
  };

  const validatePhone = (phone) => {
    if (!phone) {
      return true;
    }

    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone);
  };

  const handleSelect = (suburb) => {
    setFormData((prevData) => ({ ...prevData, location: suburb }));
    setShowDropdown(false);
  };
  // Validate form whenever formData changes
  useEffect(() => {
    const { name, email, phone, password } = formData;

    // Check if all fields are filled and phone is valid
    const isFormFilled = name && email && phone && password;
    const isValidPhone = validatePhone(phone);

    setIsPhoneValid(isValidPhone);
    setIsFormValid(isFormFilled && isValidPhone);
  }, [formData]);

  const handleRoleChange = (e) => {
    setFormData({
      ...formData,
      role: e.target.value,
    });
  };

  const closeModal = () => {
    setShowMessageModal(false);
    if (messageType === "success") {
      navigate("/login"); // Redirect on successful registration
    }
  };

  const closeMembershipModal = () => {
    setShowMembershipMessageModal(false);
    if (messageType === "success") {
      navigate("/login"); // Redirect on successful registration
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsRegistering(true);

    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("password", formData.password);
    formDataToSend.append("role", formData.role);
    formDataToSend.append("location", formData.location);
    formDataToSend.append("company", formData.company);

    if (formData.profileImage) {
      formDataToSend.append(
        "profileImage",
        formData.profileImage,
        formData.profileImage.name
      );
    }

    try {
      await axiosInstance.post("/api/register", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data", // Set this header to ensure the server recognizes it as form-data
        },
      });
      if (formData.role === "employer" || formData.role === "both") {
        setMessage(
          "Registration successful! Next step: Unlock full access with Skilled Mates Membership — post jobs, find staff, and connect instantly. Subscribe now!"
        );
        setMessageType("success");
        setShowMembershipMessageModal(true);
      } else {
        setMessage("Registration successful! You can now log in.");
        setMessageType("success");
        setShowMessageModal(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage("You are already registered, please log in.");
      } else {
        setMessage("An error occurred during registration. Please try again.");
      }
      setMessageType("error");
      setIsRegistering(false);
      setShowMessageModal(true);
    }
  };

  const handleSubscriptionEvent = (eventType) => {
    if (eventType === "subscribe") {
      closeModal();
    } else if (eventType === "later") {
      closeModal();
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Sign Up in Easy Steps</h1>
      <form onSubmit={handleSubmit}>
        {/* Form Fields */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your name"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700   font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your email"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700   font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your password"
            required
          />
        </div>
        {/* Profile Photo */}
        <div className="mb-4">
          <label
            htmlFor="profileImage"
            className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue-600 rounded-lg border border-gray-300 cursor-pointer hover:bg-blue-50 transition duration-150"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-blue-500 mb-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            <span className="text-sm font-medium">Upload Profile Image</span>
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
          </label>

          {profilePreview && (
            <img
              src={profilePreview}
              alt="Profile Preview"
              className="mt-2 w-24 h-24 rounded-full object-cover"
            />
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700   font-bold mb-2"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              !isPhoneValid ? "border-red-500" : ""
            }`}
            placeholder="Your phone number"
            required
          />
          {!isPhoneValid && (
            <p className="text-red-500 text-xs italic">
              Phone number must be numeric.
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700   font-bold mb-2"
            htmlFor="company"
          >
            Company (Optional)
          </label>
          <input
            type="text"
            name="company"
            id="company"
            value={formData.company}
            onChange={handleChange}
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your company name"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700   font-bold mb-2"
            htmlFor="location"
          >
            Location
          </label>
          <input
            type="text"
            name="location"
            placeholder="Start typing..."
            value={formData.location}
            onChange={handleChange}
            required
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {showDropdown && (
            <div className="relative">
              <div className="absolute  bg-white border border-gray-300 rounded shadow-lg w-full mt-1 max-h-60 overflow-auto">
                {loading ? (
                  <div className="p-2 text-center">
                    <BounceLoader color="#000" />
                  </div>
                ) : suburbs.length > 0 ? (
                  suburbs.map((suburb) => (
                    <div
                      key={suburb.Suburb}
                      className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                      onClick={() => handleSelect(suburb)}
                    >
                      {suburb}
                    </div>
                  ))
                ) : (
                  <div className=" "> </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700  font-bold mb-2">
            Reason for Registration
          </label>
          <label className="block text-gray-600 text-sm italic mb-4 bg-yellow-100 p-2 rounded">
            <strong>Note:</strong> You will only be able to post jobs if you
            register as an employer or both.
          </label>
          <div className="flex items-center">
            <input
              type="radio"
              name="role"
              value="worker"
              checked={formData.role === "worker"}
              onChange={handleRoleChange}
              className="mr-2 bg-white"
            />
            <label className="mr-4">Looking for Work</label>
            <input
              type="radio"
              name="role"
              value="employer"
              checked={formData.role === "employer"}
              onChange={handleRoleChange}
              className="mr-2 bg-white"
            />
            <label className="mr-4">Employer</label>
            <input
              type="radio"
              name="role"
              value="both"
              checked={formData.role === "both"}
              onChange={handleRoleChange}
              className="mr-2 bg-white"
            />
            <label>Both</label>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={!isFormValid || isRegistering} // Disable button if form is not valid
          >
            {isRegistering ? "Registering..." : "Register"}
          </button>
        </div>
      </form>
      <div className="mt-4">
        <p className="text-gray-600">
          Already have an account?{" "}
          <button
            onClick={() => navigate("/login")}
            className="text-blue-500 hover:text-blue-700"
          >
            Login here
          </button>
        </p>
      </div>

      {/* Message Modal */}
      <Modal
        isOpen={showMessageModal}
        onRequestClose={closeModal}
        contentLabel="Registration Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div
            className={`text-4xl mb-4 ${
              messageType === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {messageType === "success" ? <FaCheckCircle /> : <FaTimesCircle />}
          </div>
          <h2 className="text-xl font-bold mb-4">{message}</h2>
          <div className="flex gap-4">
            {messageType === "success" && (
              <button
                onClick={() => navigate("/login")}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Login
              </button>
            )}

            <button
              onClick={closeModal}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showMembershipMessageModal}
        onRequestClose={closeMembershipModal}
        contentLabel="Registration Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeMembershipModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div
            className={`text-4xl mb-4 ${
              messageType === "success" ? "text-green-500" : "text-red-500"
            }`}
          >
            {messageType === "success" ? <FaCheckCircle /> : <FaTimesCircle />}
          </div>
          <h3 className="font-bold mb-4">{message}</h3>
          <div className="flex gap-4">
            {messageType === "success" ? (
              <Pricing
                email={formData.email}
                laterButton={true}
                onEvent={handleSubscriptionEvent}
              />
            ) : (
              <button
                onClick={closeMembershipModal}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
