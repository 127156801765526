import React, { useState } from 'react';
import { FaEnvelope, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import axiosInstance from '../axiosInstance';

const Tasks = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/api/addEmailIntoNewsletter', { email });
      if (response.status === 200) {
        setMessage('Thank you for subscribing!');
      } else {
        setMessage('Something went wrong. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
    setEmail(''); // Clear the input field after submission
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-blue-100 flex flex-col items-center">
      <header className="w-full bg-blue-600 text-white py-12 text-center">
        <h1 className="text-4xl font-bold mb-2">🎉 New Task Feature Coming Soon: End of This Month!</h1>
        <p className="mt-4 text-lg max-w-xl mx-auto">
          Soon, you’ll be able to post tasks, receive offers, and accept the best bids for your needs. 
          Stay tuned for updates!
        </p>
      </header>

      <main className="flex-grow flex flex-col items-center mt-12 px-4 text-center">
        <h2 className="text-3xl font-semibold mb-4 text-blue-800">
          <FaEnvelope className="inline-block mr-2" /> Be the First to Know
        </h2>
        <p className="text-gray-700 mb-8 max-w-md">
          Enter your email below to subscribe and receive the latest news about this feature as soon as it’s available.
        </p>
        
        <form onSubmit={handleEmailSubmit} className="flex flex-col items-center space-y-4">
          <div className="relative w-72">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

              required
            />
            <FaEnvelope className="absolute right-3 top-3 text-gray-400" />
          </div>
          <button
            type="submit"
            className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md shadow-md"
          >
            Subscribe
          </button>
        </form>

        {message && (
          <p className={`mt-4 text-lg ${message.includes('Thank') ? 'text-green-500' : 'text-red-500'} flex items-center`}>
            {message.includes('Thank') ? <FaCheckCircle className="mr-2" /> : <FaExclamationCircle className="mr-2" />}
            {message}
          </p>
        )}
      </main>

       
    </div>
  );
};

export default Tasks;
